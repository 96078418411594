<template>
    <div>
        <div
            class="banner"
            v-if="modelType === 'website'"
            @mouseover="onMouseSwiper(true)"
            @mouseout="onMouseSwiper(false)"
        >
            <div
                @transitionstart="onTransitionstart"
                @transitionend="onTransitionend"
                class="banner-container"
                :style="bannerStyle"
            >
                <div
                    class="banner-item"
                    :style="`width: ${100 / bannerList.length}%`"
                    v-for="item in bannerList"
                    :key="item.bannerId"
                >
                    <a v-if="['ROUTE_URL', 'WEB_URL'].includes(item.action)" target="_blank" :href="item.link">
                        <img :src="item.image" />
                    </a>
                    <img v-else :src="item.image" />
                </div>
            </div>
            <div class="indicator" v-if="bannerList.length > 3">
                <template v-for="item in bannerList">
                    <div
                        class="indicator-item"
                        v-if="!item.repeat"
                        :class="activeIndicator === item.indicatorUid ? 'indicator-item-active' : ''"
                        :key="item.indicatorUid"
                        @click="onClickIndicator(item)"
                    >
                        <div></div>
                    </div>
                </template>
            </div>
            <template>
                <div class="left-icon" @click="onBannerSwiperChange('prev')">
                    <van-icon name="arrow-left" />
                </div>
                <div class="right-icon" @click="onBannerSwiperChange('next')">
                    <van-icon name="arrow" />
                </div>
            </template>
        </div>
        <van-swipe v-else :autoplay="5000" indicator-color="white">
            <template v-for="item in bannerList">
                <van-swipe-item v-if="!item.repeat" :key="item.bannerId">
                    <a v-if="['ROUTE_URL', 'WEB_URL'].includes(item.action)" target="_blank" :href="item.link">
                        <img :src="item.image" />
                    </a>
                    <img v-else :src="item.image" />
                </van-swipe-item>
            </template>
        </van-swipe>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'

let timer = 0
export default {
    data() {
        return {
            modelType: '',
            bannerList: [],
            activeIndicator: '',

            bannerStyle: null,
        }
    },
    mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize)
        if (timer) clearInterval(timer)
    },
    methods: {
        onResize() {
            let type = 'website'
            if (document.documentElement.clientWidth <= 720) {
                type = 'website-h5'
            }
            if (type !== this.modelType) {
                this.getBannerConfigApi(type)
            }
        },
        onBannerSwiperChange(type) {
            if (!this.transitionState) {
                if (type === 'prev') {
                    this.activeIndex -= 1
                } else if (type === 'next') {
                    this.activeIndex += 1
                }
                this.onSwitchBanner()
            }
            this.initSetInterval()
        },
        onSwitchBanner() {
            this.bannerStyle.transform = `translateX(-${(100 / this.bannerList.length) * this.activeIndex}%)`
            this.bannerStyle['transition-duration'] = '300ms'
            this.bannerList.forEach((item, index) => {
                if (index === this.activeIndex) {
                    this.activeIndicator = item.indicatorUid
                }
            })
        },
        onMouseSwiper(bool) {
            this.bannerAutomatic = bool
        },
        onClickIndicator(indicator) {
            // 过去头尾重复
            this.bannerList.forEach((item, index) => {
                if (indicator.bannerId === item.bannerId) {
                    this.activeIndex = index
                }
            })
            this.onSwitchBanner()
        },

        // 监听过度
        onTransitionstart() {
            this.transitionState = true
        },
        onTransitionend() {
            if (this.activeIndex >= this.bannerList.length - 1) {
                this.activeIndex = 1
            } else if (this.activeIndex <= 0) {
                this.activeIndex = this.bannerList.length - 2
            }
            this.bannerStyle.transform = `translateX(-${(100 / this.bannerList.length) * this.activeIndex}%)`
            this.bannerStyle['transition-duration'] = '0ms'

            this.transitionState = false
        },
        getBannerConfigApi(channel) {
            this.modelType = channel
            let _bannerList = []
            if (channel === 'website') {
                _bannerList = [
                    {
                        image: 'https://res.jiaoshi.fun/resource/20231030/f852e521cd3e49c485c04fc588ccc73e.png',
                        bannerId: uuidv4(),
                        indicatorUid: uuidv4(),
                        repeat: true,
                    },
                    {
                        image: 'https://res.jiaoshi.fun/resource/20231030/f852e521cd3e49c485c04fc588ccc73e.png',
                        bannerId: uuidv4(),
                        indicatorUid: uuidv4(),
                    },
                    {
                        image: 'https://res.jiaoshi.fun/resource/20231030/f852e521cd3e49c485c04fc588ccc73e.png',
                        bannerId: uuidv4(),
                        indicatorUid: uuidv4(),
                        repeat: true,
                    },
                ]
            } else {
                _bannerList = [
                    {
                        image: 'https://res.jiaoshi.fun/resource/20231030/bd937c0c84934e809d3ffcfa7a1bd0aa.png',
                        bannerId: uuidv4(),
                        indicatorUid: uuidv4(),
                        repeat: true,
                    },
                    {
                        image: 'https://res.jiaoshi.fun/resource/20231030/bd937c0c84934e809d3ffcfa7a1bd0aa.png',
                        bannerId: uuidv4(),
                        indicatorUid: uuidv4(),
                    },
                    {
                        image: 'https://res.jiaoshi.fun/resource/20231030/bd937c0c84934e809d3ffcfa7a1bd0aa.png',
                        bannerId: uuidv4(),
                        indicatorUid: uuidv4(),
                        repeat: true,
                    },
                ]
            }
            this.bannerList = _bannerList
            this.activeIndex = 1
            this.bannerStyle = {
                width: _bannerList.length * 100 + '%',
                transform: `translateX(-${(100 / _bannerList.length) * this.activeIndex}%)`,
            }
            this.activeIndicator = this.bannerList[1]['indicatorUid']
            this.$nextTick(() => {
                this.initSetInterval()
            })
        },
        initSetInterval() {
            if (this.modelType === 'website' && this.bannerList.length > 3) {
                clearInterval(timer)
                timer = setInterval(() => {
                    if (!this.bannerAutomatic) {
                        this.onBannerSwiperChange('next')
                    }
                }, 5000)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.banner {
    width: 100%;
    height: 28.125vw;
    position: relative;
    overflow: hidden;
    .banner-container {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 100%;
        .banner-item {
            height: 100%;
            img {
                pointer-events: none;
                width: 100%;
                height: 100%;
            }
        }
    }

    .indicator {
        display: flex;
        justify-content: center;
        height: 40px;
        width: 100%;
        position: absolute;
        align-items: center;
        left: 0;
        bottom: 0;
        .indicator-item {
            cursor: pointer;
            width: 64px;
            height: 6px;
            border-radius: 1px;
            background-color: rgba($color: #ebedf0, $alpha: 0.2);
            margin: 0 4px;
            overflow: hidden;
            position: relative;
        }
        .indicator-item-active {
            > div {
                position: absolute;
                width: 0;
                top: 0;
                left: 0;
                height: 6px;
                background-color: #fff;
                animation: indicatorWidth 5s linear forwards;
            }
        }
    }
    .left-icon,
    .right-icon {
        position: absolute;
        z-index: 99;
        width: 32px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($color: #000000, $alpha: 0.1);
        color: #fff;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
        cursor: pointer;
    }
    .left-icon {
        left: 0;
        border-radius: 2px 0 0 2px;
    }
    .right-icon {
        right: 0;
        border-radius: 0 2px 2px 0;
    }
}

// 移动端 UI
.van-swipe {
    img {
        pointer-events: none;
        width: 100%;
        display: block;
    }
}

@keyframes indicatorWidth {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
</style>
