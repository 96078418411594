<template>
    <div id="index" :class="deviceInfo.mobile ? 'mobile-index' : ''">
        <Header />
        <div class="content">
            <Banner />
            <Teacher />
            <About />
        </div>
        <Fotter />
    </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
import Banner from './components/Banner.vue'
import About from './components/About.vue'
import Teacher from './components/Teacher.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'index',
    components: { Header, Fotter, Banner, Teacher, About },
    computed: { ...mapGetters(['userInfo', 'deviceInfo']) },
    data() {
        return {
            productList: [],
        }
    },
    created() {
    },
    mounted() {
        if (!this.userInfo && this.$route.query.redirect) {
            this.$store.dispatch('user/showLoginDialog')
        }
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
#index {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
</style>
