<template>
    <section :class="white ? 'white-color' : ''">
        <h2 :title="titleName">
            <span class="head">{{head}}</span><span>{{title}}</span>
        </h2>
        <div class="subtitle">{{subtitle}}</div>
    </section>
</template>
<script>
export default {
    props: ['title', 'head', 'subtitle', 'white', 'titleName'],
}
</script>
<style lang="scss" scoped>
section {
    margin: 0 auto;
    padding-bottom: 16px;
    h2 {
        font-size: 18px;
    }
    .head {
        font-size: 32px;
        display: inline-block;
        margin-right: 8px;
        line-height: 30px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: -12px;
            width: 10px;
            height: 2px;
            background: $theme-color;
        }
    }
    .subtitle {
        color: #666666;
        font-size: 12px;
        margin-top: 12px;
    }
}
.white-color {
    color: #fff;
    .head {
        &::after {
            background: #fff;
        }
    }
    .subtitle {
        color: #fff;
    }
}
</style>