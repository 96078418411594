<template>
    <div class="teacher">
        <div class="teacher-content">
            <CustomTitle class="title-component" title="资力量" head="师" subtitle="TEACHER" />
        </div>
        <div class="teacher-content" @mouseover="teacherSwipePlay = 0" @mouseout="teacherSwipePlay = 2000">
            <!-- <div class="left-icon" @click="onTeacherSwiperChange('prev')">
                <van-icon name="arrow-left" />
            </div> -->
            <van-swipe ref="teacherSwiper" :autoplay="teacherSwipePlay" indicator-color="#df7e2f">
                <van-swipe-item v-for="(item, index) in teacherList" :key="index">
                    <img :src="item.cover" />
                    <div>
                        <div class="name-desc">
                            <div class="name">{{ item.name }}</div>
                            
                        </div>
                        <div class="teacher-tags">
                            <div v-for="(tag, subIndex) in item.about" :key="subIndex" class="teacher-tag">
                                {{ tag }}
                            </div>
                        </div>
                    </div>
                </van-swipe-item>
            </van-swipe>
            <!-- <div class="right-icon" @click="onTeacherSwiperChange('next')">
                <van-icon name="arrow" />
            </div> -->
        </div>
    </div>
</template>
<script>
import CustomTitle from '@/components/CustomTitle'

const teacherList = [
    {
        nickname: 'Moshu',
        name: '墨书',
        teacherNo: 'Moshu',
        cover: 'https://res.jiaoshi.fun/resource/20231031/5150e0e454e84827aa885496995da8c4.png',
        about: [
            '七年教学经验',
            '金牌讲师、培训师',
            '被学生称为 “教师摆渡人”',
            '累计教授学员10万+',
            '授课风格：',
            '满满的干货、实用的技巧、极致的服务、绝对的通过率。',
        ],
    },
]

export default {
    components: { CustomTitle },
    data() {
        return {
            teacherList: teacherList,
            teacherSwipePlay: 0,

            activeItem: teacherList[0],
        }
    },
    methods: {
        onTeacherSwiperChange(type) {
            if (this.$refs.teacherSwiper) {
                this.$refs.teacherSwiper[type]()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.teacher {
    background: #f7f7f7;
    padding: 40px 16px 60px;
}
.teacher-content {
    max-width: $center-width;
    margin: 0 auto;
    position: relative;
    .left-icon,
    .right-icon {
        position: absolute;
        z-index: 99;
        width: 32px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($color: #000000, $alpha: 0.1);
        color: #fff;
        top: 168px;
        font-size: 32px;
    }
    .left-icon {
        left: 0;
        border-radius: 2px 0 0 2px;
    }
    .right-icon {
        right: 0;
        border-radius: 0 2px 2px 0;
    }
    ::v-deep .van-swipe-item {
        display: flex;
        position: relative;
        > img {
            width: 240px;
            height: 337px;
            border-radius: 8px;
            background: none;
            flex-shrink: 0;
            margin: 0 48px 10px 20px;
            position: relative;
            object-fit: contain;
            z-index: 2;
            pointer-events: none;
        }
        > div {
            .name-desc {
                display: flex;
            }
            .name {
                width: 182px;
                height: 73px;
                background: $theme-color;
                position: relative;
                border-radius: 4px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 34px;
                font-weight: 700;
                &::before {
                    position: absolute;
                    content: 'TEACHER';
                    top: 0;
                    left: 18px;
                    transform: rotate(90deg);
                    transform-origin: left;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            .desc {
                margin-left: 14px;
                color: #000;
                font-size: 16px;
                position: relative;
                height: 20px;
                align-self: flex-end;
                margin-bottom: 4px;
                span {
                    position: relative;
                    z-index: 1;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 50%;
                    width: 80px;
                    height: 4px;
                    transform: translateX(-50%);
                    background: $theme-color;
                }
            }
        }
        .teacher-tags {
            margin-top: 32px;
            font-size: 18px;
            line-height: 2em;
            span {
                color: $theme-color;
                padding-right: 8px;
            }
        }
        .van-icon-down {
            transform: rotate(-90deg);
            margin-left: 6px;
        }
    }
    ::v-deep .van-swipe__indicator {
        background: #333;
    }
}

@media screen and (max-width: 960) and (min-width: 720px) {
    .title-component {
        text-align: center;
    }
    .teacher-content {
        .right-icon,
        .left-icon {
            display: none;
        }
    }
}
@media screen and (max-width: 719px) and (min-width: 520px) {
    .title-component {
        text-align: center;
    }
    .teacher-content {
        .right-icon,
        .left-icon {
            display: none;
        }
        ::v-deep .van-swipe-item {
            justify-content: center;
            > img {
                width: 180px;
                height: 252px;
                border-radius: 4px;
                margin-right: 20px;
            }
            > div {
                .name {
                    width: 90px;
                    height: 36px;
                    font-size: 18px;
                    border-radius: 2px;
                    &::before {
                        transform: rotate(90deg) scale(0.4);
                        left: 8px;
                        top: 0;
                    }
                }
                .desc {
                    margin-left: 12px;
                    color: #000;
                    font-size: 14px;
                    height: 18px;
                    &::after {
                        width: 72px;
                    }
                }
            }
            .teacher-tags {
                margin-top: 16px;
                font-size: 14px;
                line-height: 2em;
            }
        }
    }
}
@media screen and (max-width: 519px) {
    .title-component {
        text-align: center;
    }
    .teacher-content {
        .right-icon,
        .left-icon {
            display: none;
        }
        ::v-deep .van-swipe-item {
            justify-content: center;
            position: relative;
            > img {
                width: 120px;
                height: 168px;
                border-radius: 4px;
                margin-right: 12px;
            }
            > div {
                .name {
                    width: 80px;
                    height: 32px;
                    font-size: 16px;
                    border-radius: 2px;
                    &::before {
                        transform: rotate(90deg) scale(0.3);
                        left: 8px;
                        top: 0;
                    }
                }
                .desc {
                    margin-left: 8px;
                    color: #000;
                    font-size: 14px;
                    height: 18px;
                    &::after {
                        width: 72px;
                    }
                }
            }
            .teacher-tags {
                margin-top: 12px;
                font-size: 12px;
                line-height: 1.6em;
            }
        }
    }
}
</style>
